import type { GameSiteSetting } from 'genteras-subagent/src/constant/Enums';

export const FM_HANDICAPS: ('A' | 'B' | 'C')[] = ['A', 'B', 'C'];
export const WS_HANDICAPS: ('A' | 'B' | 'C' | 'D' | 'E' | 'F')[] = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
];

export enum GameSite {
  SA = 'sa',
  SP = 'sp',
  FM = 'fm',
  WS = 'ws',
  BETSWIZ = 'betswiz',
  FM_HK = 'fm_hk',
  FM_TW = 'fm_tw',
  FM_VI = 'fm_vi',
  FM_MY = 'fm_my',
  FM_TH = 'fm_th',
  FM_MA = 'fm_ma',
  FM_MMK2 = 'fm_mmk2',
  FM_KR = 'fm_kr',
  FM_JP = 'fm_jp',
  FM_MO = 'fm_mo',
  FM_KSTW = 'fm_kstw',
  SA_SA = 'sa_sa',
  SA_VI = 'sa_vi',
  SA_MY = 'sa_my',
  SA_TH = 'sa_th',
  SA_MA = 'sa_ma',
  SA_MMK2 = 'sa_mmk2',
  SA_JP = 'sa_jp',
  SA_MO = 'sa_mo',
  SA_KSTW = 'sa_kstw',
  SA_KR = 'sa_kr',
  SA_PK = 'sa_pk',
  SP_VI = 'sp_vi',
  SP_MY = 'sp_my',
  SP_TH = 'sp_th',
  SP_MA = 'sp_ma',
  SP_MMK2 = 'sp_mmk2',
  SP_KR = 'sp_kr',
  SP_PK = 'sp_pk',
  SP_JP = 'sp_jp',
  SP_MO = 'sp_mo',
  SP_KSTW = 'sp_kstw',
  WS_HK = 'ws_hk',
  WS_TW = 'ws_tw',
  WS_VI = 'ws_vi',
  WS_MY = 'ws_my',
  WS_TH = 'ws_th',
  WS_MA = 'ws_ma',
  WS_MMK2 = 'ws_mmk2',
  WS_KR = 'ws_kr',
  WS_PK = 'ws_pk',
  BETSWIZ_PK = 'betswiz_pk',
  BO_HK = 'bo_hk',
  BO_TW = 'bo_tw',
  BO_VI = 'bo_vi',
  BO_MY = 'bo_my',
  BO_TH = 'bo_th',
  BO_MA = 'bo_ma',
  BO_MMK2 = 'bo_mmk2',
  BO_KR = 'bo_kr',
  BO_PK = 'bo_pk',
  BO_JP = 'bo_jp',
  BO_MO = 'bo_mo',
  BO_KSTW = 'bo_kstw',
  BO_KSCN = 'bo_kscn',
  BO = 'bo',
  PC_HK = 'pc_hk',
  PC_TW = 'pc_tw',
  PC_VI = 'pc_vi',
  PC_MY = 'pc_my',
  PC_TH = 'pc_th',
  PC_MA = 'pc_ma',
  PC_MMK2 = 'pc_mmk2',
  PC_KR = 'pc_kr',
  PC_PK = 'pc_pk',
  PC_JP = 'pc_jp',
  PC_MO = 'pc_mo',
  PC_KSTW = 'pc_kstw',
  PC_KSCN = 'pc_kscn',
  PC = 'pc',

  EVO_HK = 'evo_hk',
  EVO_TW = 'evo_tw',
  EVO_VI = 'evo_vi',
  EVO_MY = 'evo_my',
  EVO_TH = 'evo_th',
  EVO_MA = 'evo_ma',
  EVO_MMK2 = 'evo_mmk2',
  EVO_KR = 'evo_kr',
  EVO_PK = 'evo_pk',
  EVO_JP = 'evo_jp',
  EVO_MO = 'evo_mo',
  EVO = 'evo',

  AMEBA = 'ameba',
  AMEBA_KSTW = 'ameba_kstw',
  AMEBA_KSCN = 'ameba_kscn',
  RG = 'rg',
  RG_KSTW = 'rg_kstw',
  RG_KSCN = 'rg_kscn',
}

const gameSiteToSorting = new Map<GameSite, number>([
  [GameSite.SA, 1],
  [GameSite.SP, 2],
  [GameSite.FM, 3],
  [GameSite.WS, 4],
  [GameSite.BETSWIZ, 5],
  [GameSite.FM_HK, 6],
  [GameSite.BO, 7],
  [GameSite.PC, 8],
  [GameSite.EVO, 9],
  [GameSite.AMEBA, 10],
  [GameSite.RG, 11],
]);

export const getGameSiteSorting = (gameSite: GameSite | string) =>
  gameSiteToSorting.get(convertToMasterSite(gameSite as GameSite)) ?? 999;

export function gameSiteSorting(
  a: GameSite | string | null | undefined,
  b: GameSite | string | null | undefined
) {
  const lastIndex = 9999;
  const aSort =
    a === null || a === undefined
      ? lastIndex
      : gameSiteToSorting.get(convertToMasterSite(a as GameSite)) ?? lastIndex;
  const bSort =
    b === null || b === undefined
      ? lastIndex
      : gameSiteToSorting.get(convertToMasterSite(b as GameSite)) ?? lastIndex;
  return aSort - bSort;
}

export const gameSites = [
  {
    gameSite: GameSite.FM,
    platformName: 'FM Gaming',
    sort: gameSiteToSorting.get(GameSite.FM),
  },
  {
    gameSite: GameSite.SA,
    platformName: 'SA Gaming',
    sort: gameSiteToSorting.get(GameSite.SA),
  },
  {
    gameSite: GameSite.SP,
    platformName: 'SimplePlay',
    sort: gameSiteToSorting.get(GameSite.SP),
  },
  {
    gameSite: GameSite.WS,
    platformName: 'Sports',
    sort: gameSiteToSorting.get(GameSite.WS),
  },
  {
    gameSite: GameSite.BETSWIZ,
    platformName: 'Betswiz',
    sort: gameSiteToSorting.get(GameSite.BETSWIZ),
  },
  {
    gameSite: GameSite.BO,
    platformName: 'Binary Option',
    sort: gameSiteToSorting.get(GameSite.BO),
  },
  {
    gameSite: GameSite.PC,
    platformName: 'Phoenix Club',
    sort: gameSiteToSorting.get(GameSite.PC),
  },
  {
    gameSite: GameSite.EVO,
    platformName: 'Evolution',
    sort: gameSiteToSorting.get(GameSite.EVO),
  },
  {
    gameSite: GameSite.AMEBA,
    platformName: 'Ameba',
    sort: gameSiteToSorting.get(GameSite.AMEBA),
  },
  {
    gameSite: GameSite.RG,
    platformName: 'RG',
    sort: gameSiteToSorting.get(GameSite.RG),
  },
];

export const ALL_SA = [
  GameSite.SA,
  GameSite.SA_SA,
  GameSite.SA_VI,
  GameSite.SA_MY,
  GameSite.SA_TH,
  GameSite.SA_MA,
  GameSite.SA_MMK2,
  GameSite.SA_KR,
  GameSite.SA_PK,
  GameSite.SA_JP,
  GameSite.SA_MO,
  GameSite.SA_KSTW,
];

export const ALL_SP = [
  GameSite.SP,
  GameSite.SP_VI,
  GameSite.SP_MY,
  GameSite.SP_TH,
  GameSite.SP_MA,
  GameSite.SP_MMK2,
  GameSite.SP_KR,
  GameSite.SP_PK,
  GameSite.SP_JP,
  GameSite.SP_MO,
  GameSite.SP_KSTW,
];

export const ALL_FM = [
  GameSite.FM,
  GameSite.FM_HK,
  GameSite.FM_TW,
  GameSite.FM_VI,
  GameSite.FM_MY,
  GameSite.FM_TH,
  GameSite.FM_MA,
  GameSite.FM_MMK2,
  GameSite.FM_KR,
  GameSite.FM_JP,
  GameSite.FM_MO,
  GameSite.FM_KSTW,
];

export const ALL_WS = [
  GameSite.WS,
  GameSite.WS_HK,
  GameSite.WS_TW,
  GameSite.WS_VI,
  GameSite.WS_MY,
  GameSite.WS_TH,
  GameSite.WS_MA,
  GameSite.WS_MMK2,
  GameSite.WS_KR,
  GameSite.WS_PK,
];

export const ALL_BETSWIZ = [GameSite.BETSWIZ, GameSite.BETSWIZ_PK];

export const ALL_BO = [
  GameSite.BO,
  GameSite.BO_HK,
  GameSite.BO_TW,
  GameSite.BO_VI,
  GameSite.BO_MY,
  GameSite.BO_TH,
  GameSite.BO_MA,
  GameSite.BO_MMK2,
  GameSite.BO_KR,
  GameSite.BO_PK,
  GameSite.BO_JP,
  GameSite.BO_MO,
  GameSite.BO_KSTW,
  GameSite.BO_KSCN,
];

export const ALL_PC = [
  GameSite.PC,
  GameSite.PC_HK,
  GameSite.PC_TW,
  GameSite.PC_VI,
  GameSite.PC_MY,
  GameSite.PC_TH,
  GameSite.PC_MA,
  GameSite.PC_MMK2,
  GameSite.PC_KR,
  GameSite.PC_PK,
  GameSite.PC_JP,
  GameSite.PC_MO,
  GameSite.PC_KSTW,
  GameSite.PC_KSCN,
];

export const ALL_EVO = [
  GameSite.EVO,
  GameSite.EVO_HK,
  GameSite.EVO_TW,
  GameSite.EVO_VI,
  GameSite.EVO_MY,
  GameSite.EVO_TH,
  GameSite.EVO_MA,
  GameSite.EVO_MMK2,
  GameSite.EVO_KR,
  GameSite.EVO_PK,
  GameSite.EVO_JP,
  GameSite.EVO_MO,
];

export const ALL_AMEBA = [
  GameSite.AMEBA,
  GameSite.AMEBA_KSTW,
  GameSite.AMEBA_KSCN,
];

export const ALL_RG = [GameSite.RG, GameSite.RG_KSTW, GameSite.RG_KSCN];

export const ALL_SPORTS = [...ALL_FM, ...ALL_BETSWIZ, ...ALL_WS];

const gameSiteToMasterGameSite = new Map<GameSite, GameSite>([
  ...ALL_SA.map((sa) => [sa, GameSite.SA] as const),
  ...ALL_SP.map((sp) => [sp, GameSite.SP] as const),
  ...ALL_FM.map((fm) => [fm, GameSite.FM] as const),
  ...ALL_WS.map((ws) => [ws, GameSite.WS] as const),
  ...ALL_BETSWIZ.map((betswiz) => [betswiz, GameSite.BETSWIZ] as const),
  ...ALL_BO.map((bo) => [bo, GameSite.BO] as const),
  ...ALL_PC.map((pc) => [pc, GameSite.PC] as const),
  ...ALL_EVO.map((evo) => [evo, GameSite.EVO] as const),
  ...ALL_AMEBA.map((ameba) => [ameba, GameSite.AMEBA] as const),
  ...ALL_RG.map((rg) => [rg, GameSite.RG] as const),
]);

export const convertToMasterSite = (gameSite: GameSite) => {
  const masterGameSite = gameSiteToMasterGameSite.get(gameSite);

  if (!masterGameSite) {
    throw `Incorrect gameSite: ${gameSite}`;
  }

  return masterGameSite;
};

export const findMissingPlatformGameSettings = (
  parentGameSiteSettings: GameSiteSetting[],
  gameSiteSettings: GameSiteSetting[]
): GameSite[] => {
  return parentGameSiteSettings
    .map((x) => convertToMasterSite(x.gameSite))
    .filter(
      (x) =>
        !(gameSiteSettings || [])
          .map((y) => convertToMasterSite(y.gameSite))
          .includes(x)
    );
};

export const isMissingGameSiteSettings = (
  parentGameSiteSettings: GameSiteSetting[],
  gameSiteSettings: GameSiteSetting[]
) => {
  return (
    findMissingPlatformGameSettings(parentGameSiteSettings, gameSiteSettings)
      .length > 0
  );
};

export enum WhitelabelClient {
  CASA_98 = 'CASA_98',
  TW_AGENT = 'TW_AGENT',
  VI_AGENT = 'VI_AGENT',
  MY_AGENT = 'MY_AGENT',
  TH_AGENT = 'TH_AGENT',
  MA_AGENT = 'MA_AGENT',
  MMK2_AGENT = 'MMK2_AGENT',
  KR_AGENT = 'KR_AGENT',
  PK_AGENT = 'PK_AGENT',
  JP_AGENT = 'JP_AGENT',
  MO_AGENT = 'MO_AGENT',
}

export const haveGames = (
  gameSite: GameSite[],
  gameSiteSettings: GameSiteSetting[]
) => {
  return gameSiteSettings.find((gameSiteSetting) =>
    gameSite.includes(gameSiteSetting.gameSite)
  );
};

export function whichGameSite(site: GameSite) {
  // this is a hack but at least it's abstracted, so we can change it easily if needed
  switch (site.split('_')[0]) {
    case 'sa':
      return GameSite.SA;
    case 'sp':
      return GameSite.SP;
    case 'fm':
      return GameSite.FM;
    case 'ws':
      return GameSite.WS;
    case 'betswiz':
      return GameSite.BETSWIZ;
    case 'bo':
      return GameSite.BO;
    case 'pc':
      return GameSite.PC;
    case 'evo':
      return GameSite.EVO;
    case 'ameba':
      return GameSite.AMEBA;
    case 'rg':
      return GameSite.RG;
    default:
      return null;
  }
}

export const TransferWalletGameSites = [...ALL_BETSWIZ];
